import React, { Component, Fragment } from 'react';
import SEO from '../SEO/SEO';
import Slider from '../Slider';
import { Link } from 'gatsby';
import './WordpressContent.scss';
import WordpressContentDiv from './WordpressContentDiv';
import {ButtonsComponentHeightContext} from '../buttonsComponentHeightContext';
import SvgIconReturn from '../../svg/icons/SvgIconReturn';
import SvgLoader from '../../svg/icons/SvgLoader';
import PropTypes from 'prop-types';
import constants from '../../constants/javascriptConstants';


export default class WordpressContent extends Component {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        location: PropTypes.object,
    };

    static defaultProps = { location: '' };

    render() {
        const { wpData, isLoading, location } = this.props;
        let returnPath = '/' + location.pathname.split('/')[1];
        if (constants.economicLinks.some(linkElement => location.pathname.includes(linkElement))) {
            returnPath='/kompetencje-ekonomiczne/';
        } else if (constants.futureLinks.some(linkElement => location.pathname.includes(linkElement))) {
            returnPath='/kompetencje-przyszlosci/';
        } else if (constants.workLinks.some(linkElement => location.pathname.includes(linkElement))) {
            returnPath='/praca-przyszlosci/';
        } else {
            returnPath='/';
        }

        return (
            <React.Fragment>
                <div>
                    <SEO
                        isSubpage
                        subpagePath={location.pathname}
                        subpageTittle={wpData ? wpData.title.rendered : ''}
                    />
                    <Slider>
                        <div>
                            <Link to={returnPath} className="returnButton">
                                <SvgIconReturn height={50} />
                            </Link>
                            <ButtonsComponentHeightContext.Consumer>
                                {({buttonsComponentInnerHeight}) => (
                                    <Fragment>
                                        {isLoading && (
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <SvgLoader height={100} style={{marginTop: '50px'}}/>
                                            </div>)
                                        }
                                        {!isLoading && (
                                            <WordpressContentDiv
                                                wpData={wpData}
                                                startingHeight={buttonsComponentInnerHeight}
                                            />
                                        )}
                                    </Fragment>
                                )}
                            </ButtonsComponentHeightContext.Consumer>
                        </div>
                    </Slider>
                </div>
            </React.Fragment>

        );
    }
}
