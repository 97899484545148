import React, { Component } from 'react';
import './WordpressContent.scss';
import constants from '../../constants/javascriptConstants';
import PropTypes from 'prop-types';

export default class WordpressContentDiv extends Component {

    static propTypes = {
        startingHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    };

    static defaultProps = {
        startingHeight: 0
    };

    state = {
        contentLargerThanContainer: true,
        expandContent: false,
    };

    constructor(props) {
        super(props);
        this.wpContentRef = React.createRef();
        this.wpInnerContentRef = React.createRef();
    }

    componentDidMount() {
        this.verifyComponentHeightOnMount();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.startingHeight !== this.props.startingHeight) {
            this.verifyComponentHeight();
        }
    }

    verifyComponentHeightOnMount = () => {
        if(this.wpInnerContentRef && this.wpInnerContentRef.current && this.wpContentRef) {
            this.setState({
                contentLargerThanContainer: this.wpInnerContentRef.current.offsetHeight+constants.imageHeight - 100 > this.wpContentRef.current.offsetHeight
            });
        } else {
            this.setState({
                contentLargerThanContainer: false
            });
        }
    };

    verifyComponentHeight = () => {
        if(this.wpInnerContentRef && this.wpInnerContentRef.current && this.wpContentRef) {
            this.setState({
                contentLargerThanContainer: this.wpInnerContentRef.current.offsetHeight - 100 > this.wpContentRef.current.offsetHeight
            });
        } else {
            this.setState({
                contentLargerThanContainer: false
            });
        }
    };

    render() {
        const { startingHeight, wpData } = this.props;
        const { contentLargerThanContainer, expandContent } = this.state;
        return (
            <React.Fragment>
                <div
                    className={'wpContent' + (contentLargerThanContainer ? '' : ' verticalAlign')}
                    ref={this.wpContentRef}
                    style={{
                        height: expandContent ? 'auto' : startingHeight,
                        maxHeight: expandContent ? '10000px' : startingHeight,
                        transition: '.8s max-height ease-in-out',
                    }}
                >
                    <div
                        className="wpInnerContent"
                        ref={this.wpInnerContentRef}
                    >
                        <h2 className="articleTitle">{wpData.title.rendered}</h2>
                        <div
                            dangerouslySetInnerHTML={{ __html: wpData.content.rendered }}
                        />
                    </div>
                </div>
                {contentLargerThanContainer && expandContent === false &&
                    <div className="readMoreContainer">
                        <div className="readMoreButton" onClick={() => this.setState({ expandContent: true })}>Czytaj dalej</div>
                    </div>
                }
            </React.Fragment>
        );
    }
}
